/* CarouselComponent.css */
.carousel-image {
  object-fit: cover;
  height: 70vh;
  width: 100%; 
  display: block;
}
.carousalComponent{
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {

  .carousalComponent{
    object-fit: cover;
    height: 23vh;
    /* width: 100%;  */
    display: block;
    }
  .carousel-image {
    object-fit: cover;
    height:auto;
    width: 100%; 
    display: block;
  }
}
@media (min-width: 1200px) {
  .carousel-image {
    height: 70vh; 
  }
}