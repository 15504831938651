.homeContainer {
    width: 100% !important;
    height: 100% !important;
}

/* Responsive Styles */
@media (max-width: 600px) {
    .homeContainer {
        width: 100% !important;
        height: 100% !important;
    }
}