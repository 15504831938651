.homepage-part3 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 900px;
  background-color: transparent;
  background-image: url('https://www.littlemillennium.com/assets/img/about/webp/about-us-bg-2.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  position: relative;
  z-index: 9999;
}

.feature-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.feature-item {
  width: 300px;
  height: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.feature-image {
  height: 150px;
  width: auto;
  position: relative;
  bottom: 24%;
  transition: transform 0.2s;
  /* margin-top: 10px;  */
}

.feature-image:hover {
  transform: scale(1.05);
}

.feature-box {
  position: relative;
  bottom: 24%;
}


.feature-title {
  font-family: 'Appleberry', sans-serif;
  font-size: 32px;
  font-weight: 400;
  color: #000;
  text-transform: capitalize;
  padding-top: 15px;
}

.feature-description {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #000;
  text-transform: capitalize;
  text-align: justify;
  padding: 15px;
}


/* Responsive Styles */
@media (max-width: 600px) {
  .homepage-part3 {
    width: 100%;
    height: auto;
    padding-top: 70px;
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
  }

  .feature-container {
    display: flex !important;
    flex-direction: column !important;
    gap: 70px !important;
  }

  .feature-item {
    width: 300px;
    height: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }

  .feature-image {
    height: 120px;
    width: auto;
    position: relative;
    bottom: 20%;
    transition: transform 0.2s;
  }
  .feature-image:hover {
    transform: scale(1.05);
  }
}