/* Optional CSS in AdminList.css */
.admin-list-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.admin-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.admin-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: left;
  background-color: #f9f9f9;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.admin-card-inner {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.admin-photo {
  height: 130px;
  width: 130px;
  border-radius: 8px;
}

.admin-details {
  flex: 1;
}

.admin-details h2 {
  margin: 0 0 10px;
}

.admin-details p {
  margin: 5px 0;
}

.admin-details a {
  color: #007bff;
  text-decoration: none;
}

.admin-details a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .admin-card-inner {
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
  }
  .admin-card {
    border: none;
    box-shadow: none;
  }

  .admin-photo {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
}
