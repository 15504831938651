.topNavbar {
  width: 100%;
  background-color: #ed5874 ;
  padding: 6px 0px;
  height: 35px;
}

.topNavbar-info{
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.topNavbar-number,
.topNavbar-email {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  /* font-weight: 500; */
  padding-bottom: 15px;
  color:white;
}

@media (max-width: 600px) {
  .topNavbar {
    width: 100%;
    height: 100%;
  }
  .topNavbar-info{
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 10px;
  }
  .topNavbar-number,
  .topNavbar-email {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    /* font-weight: 500; */
    /* padding-bottom: 15px; */
    color:white;
  }


}