.contact-container {
    width: 100%;
    height: auto;
}

.contact-us-section {
    background-image: url('https://heraldkids.com/images/contactb.jpg');
    background-size: cover;
    background-position: center;
    padding: 40px;
    color: white;
    text-align: center;
    min-height: 600px;
    width: 100%;
}

.contact-header {
    background-image: url('https://heraldkids.com/images/banner-box.png');
    background-size: cover;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 457px;
    justify-content: center;
    left: 7%;
    padding: 79px 120px 98px 40px;
    position: absolute;
    text-align: center;
    width: 513px;

}

.contact-title {
    position: relative;
    z-index: 1;
    color: black;
    font-size: 40px;
    font-weight: bold;
}

.contact-message {
    position: relative;
    z-index: 1;
    color: black;
    font-size: 25px;
}

@media (max-width: 600px) {
    .contact-container {
        width: 100%;
        height: auto;
    }

    .contact-us-section {
        width: 100%;
        height: auto;
    }

    .contact-header {
        width: 100%;
        left: 0%;
        height: auto;
    }


}