.homepage-container {
  width: 100%;
  height: auto;
  overflow-x: hidden; 
}

.testimonial-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: nowrap; 
  transition: transform 0.5s ease;
}

.programs-title {
  text-align: center;
}

.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 10px;
  width: 180px; 
  background: rgb(240, 240, 240);
  border: 1px solid #333;
  /* box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.13); */
  border-radius: 10px;
  flex-shrink: 0; 
}

.testimonial-image {
  height: 150px;
  width: auto;
  padding-bottom: 10px;
  border-radius: 10px;
}

.testimonial-content {
  text-align: center;
}

.testimonial-name {
  font-size: 16px;
  padding: 10px 0;
}

.testimonial-description {
  font-size: 14px;
  padding: 10px;
  color: #58595b;
  text-transform: capitalize;
  text-align: justify;
}

/* Mobile-specific adjustments */
@media (max-width: 600px) {
  .testimonial-wrapper {
    display: flex;
    flex-direction: row; 
    width: auto; 
  }

  .testimonial {
    width: 180px; 
    margin: 0 5%;
  }

  .homepage-container {
    width: 100%;
    height: auto;
  }
}