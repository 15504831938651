.heading_container h2 {
    text-transform: uppercase;
    font-weight: bold;
  }

  /* Container for the principal desk */
.principal-desk {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px; /* Adds some space between the sections */
  }
  
  /* Image styles */
  .admin_photo {
    height: 130px;
     /* Ensures the image does not exceed this width */
    border-radius: 10px;
  }
  
  /* Headings and paragraphs */
  .principal-desk h2 {
    margin: 10px 0;
  }
  
  .principal-desk p {
    margin: 10px 0;
  }

  /* Modal content styling */
.modal-content {
    width: 80%;
    max-width: 600px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff; /* White background */
    position: relative; /* Ensure it's positioned correctly inside the overlay */
    margin: 0 auto; /* Center it horizontally */
  }

  /* Notice board styling */
#noticeBoard {
    width: 100%;
    max-width: 600px;
    height: 320px;
    overflow: hidden;
    border-radius: 10px;
    padding: 1rem;
    background-color: #2d3404e0; /* Dark green background */
    border: 5px solid #abb408; /* Light green border */
    position: relative;
  }
  
  /* Styling for each notice */
  #noticeBoard p {
    margin: 10px 0;
    cursor: pointer;
    color: #ffff00; /* Yellow text color */
    text-decoration: underline;
  }