/* About.css */
.program-overview {
    width: 100%;
    height: auto;
}

.program-intro {
    background-image: url('https://heraldkids.com/images/admission.jpg');
    background-size: cover;
    background-position: center;
    color: white;
    padding: 20px;
    min-height: 500px;
}


.program-header {
    background-image: url('https://heraldkids.com/images/banner-box.png');
    background-size: cover;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 457px;
    justify-content: center;
    left: 7%;
    padding: 79px 120px 98px 40px;
    position: absolute;
    text-align: center;
    width: 513px;
}

.program-title {
    position: relative;
    z-index: 1;
    color: black;
    font-size: 40px;
    font-weight: bold;
}


.program-tagline {
    position: relative;
    z-index: 1;
    color: black;
    font-size: 25px;
}

.program-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    padding: 30px;
    color: white;
    width: 100%;
    height: 400px;
}

.program-container1 {
    display: flex;
    flex-direction: row;
    gap: 90px;
    align-items: center;
    padding-left: 50px;
    padding-top: 20px;
    color: white;
    width: 100%;
    height: 400px;
}

.program-container2 {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    align-items: center;
    padding-top: 30px;
    color: white;
    width: 100%;
    height: 450px;
}

.program-container3 {
    display: flex;
    flex-direction: row;
    gap: 90px;
    align-items: center;
    padding-left: 50px;
    padding-top: 20px;
    color: white;
    width: 100%;
    height: 500px;
}

.program-information {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}


.program-section-title {
    font-size: 35px;
    font-weight: bold;
    color: #624dd6;
}

.program-section-description {
    width: 600px;
    line-height: 40px;
    text-align: justify;
    font-size: 20px;
    color: black;
}

.program-image-wrapper {
    width: 100%;
    height: auto;
    padding-left: 30px;
}

.program-image {
    width: 80% !important;
    height: 340px !important;
    border-radius: 20px;
}

.pre-nursery-information {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}

.pre-nursery-title {
    font-size: 35px;
    font-weight: bold;
    color: #624dd6;
}

.pre-nursery-description {
    width: 500px;
    line-height: 30px;
    text-align: justify;
    font-size: 20px;
    color: black;
}

.pre-nursery-image-wrapper {
    width: 100%;
    height: auto;
    padding-left: 30px;
}

.pre-nursery-image {
    width: 90% !important;
    height: 390px !important;
    border-radius: 20px;
}


@media (max-width: 600px) {
    .program-overview {
        width: 100%;
        height: auto;
    }

    .program-intro {
        width: 100%;
        height: auto;
    }

    .program-header {
        width: 100%;
        left: 0%;
        height: auto;
    }

    .program-container {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 20px;
        padding: 20px !important;
    }

    .program-container1 {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 20px;
        padding: 20px !important;
        
    }

    .program-container2 {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 20px;
        padding: 20px !important;
        
    }

    .program-container3 {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 20px;
        padding: 20px !important;
    }

    .program-information,
    .pre-nursery-information {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .pre-nursery-title,
    .program-section-title {
        font-size: 30px;
    }

    .program-section-description,
    .pre-nursery-description {
        width: 100% !important;
        line-height: 20px;
        text-align: justify;
        font-size: 22px;
    }

    .pre-nursery-image-wrapper {
        width: 100%;
        height: 100%;
        padding: 0px;
    }

    .program-image-wrapper {
        width: 100%;
        height: 100%;
        padding-left: 0px;
    }

    .pre-nursery-image {
        width: 100% !important;
        height: auto !important;
        border-radius: 20px;
    }

    .program-image {
        width: 100% !important;
        height: auto !important;
        border-radius: 20px;
    }

}