.terms-and-conditions-container {
  width: 100%;
  height: auto;
  padding: 30px;
  margin: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.terms-header {
  font-weight: 600;
  font-size: 32px;
  padding-bottom: 10px;
}

.terms-welcome-title {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.25em;
}

.terms-introduction,
.terms-location,
.terms-acceptance,
.terms-cookies-definition,
.terms-license-description,
.terms-hyperlinking-description,
.terms-content-liability-definition,
.terms-reservation-rights-definition,
.terms-removal-links-description,
.terms-disclaimer-introduction,
.terms-free-service-notice,
.terms-disclaimer-conclusion,
.terms-iframes-definition,
.terms-cookies-description,
.terms-terminology-definition {
  padding-bottom: 15px;
  font-size: 18px;
  text-align: justify;
  padding-right: 30px;
}

.terms-terminology-header,
.terms-cookies-header,
.terms-license-header,
.terms-hyperlinking-header,
.terms-iframes-header,
.terms-content-liability-header,
.terms-reservation-rights-header,
.terms-removal-links-header,
.terms-disclaimer-header {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.25em;
}

.terms-restriction-list,
.terms-allowed-hyperlinking-list,
.terms-disclaimer-conditions-list {
  margin-left: 20px;
  color: #444;
}

.terms-restriction-list li,
.terms-allowed-hyperlinking-list li,
.terms-disclaimer-conditions-list li .terms-hyperlinking-conditions-list li {
  padding-bottom: 10px;
  list-style: decimal;
}

@media (max-width: 600px) {
  .terms-and-conditions-container {
      width: 100%;
      height: auto;
      padding: 10px;
  }
}