/* Navbar.css */
.headerNavbar {
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 70px;
  /* padding: 0 40px !important; */
  z-index: 1;
}

.logo {
  height: 75px; 
  width: auto; 
  z-index: 1;
  /* margin-top: -1px; */
  /* padding-left: 20px; */
  text-align: center;
  padding: .5rem;
}


.navbar-brand {
  font-size: 24px;
  font-weight: bold;
  color: #3A4347;
  text-decoration: none;
  transition: color 0.3s;
}

.navbar-brand:hover {
  color: #007BFF;
}

.navbar-links {
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding-top: 10px;
  text-align: center;
}

.navbar-links a {
  color: #3A4347;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  /* padding: 10px; */
}

.navbar-links a:hover {
  text-decoration: none;
  color:#ed5874 ;
}

.contact-info {
  display: flex;
  gap: 10px;
  color: #3A4347;
  /* padding-bottom: 30px; */
  text-align: center;
}

.mobile-number,
.email {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 15px;
  color: #ed5874;
}

/* Mobile styles */
.hamburger {
  display: none;
  cursor: pointer;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -250px;
  width: 250px;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease;
  padding: 20px;
  z-index: 1000;
}

.sidebar.open {
  right: 0;
}

.sidebar-links {
  list-style: none;
  padding: 0;
}

.sidebar-links li {
  margin: 20px 0;
}

@media (max-width: 600px) {
  .sidebar {
    background-color: #eceaea;
    height: 100%;
    z-index: 1;
    /* width: 300px; */

  }

  .sidebar-links a {
    font-size: 25px;
    color:black; 
    text-decoration: none; 
  }

  .sidebar-links a:hover {
    /* color: greenyellow;  */
  }

  .navbar {
    width: 100%;
    height: auto;
  }

  .navbar-links {
    display: none; 
  }

  .hamburger {
    display: block; 
    font-size: 40px;
    padding-right: 10px;
  }
  .contact-info{
    display: none;
  }
}
