.statistic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  /* padding: 20px 20px;  */
  /* background-color: #e9c8b3; */
  height: 400px;
}

.statistic-item {
  border: 2px solid #ccc;
  width: 230px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: transform 0.2s;
}

.statistic-item:hover {
  transform: scale(1.05);
}

.statistic-value {
  font-size: 35px;
  font-weight: 700;
  /* color: #6fa732; */
  background-image: linear-gradient(to right, #167d53, #adc41d);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.statistic-label {
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .statistic-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }

  .statistic-item {
    flex: 1 1 calc(50% - 20px);
    box-sizing: border-box;
    text-align: center;
  }

  .statistic-value {
    font-size: 24px;
    font-weight: bold;
  }

  .statistic-label {
    font-size: 14px;
  }
}