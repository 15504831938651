.homepage-part5 {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 20px;
}

.programs-title {
  font-family: Livvic, sans-serif;
  font-size: 30px;
  color: #212121;
  font-weight: bold;
  padding-bottom: 5px;
}

.programs-intro {
  font-family: Arial, Helvetica, sans-serif;
  padding-bottom: 25px;
  font-weight: bold;
}

.programsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.program-box {
  width: 250px;
  height: 450px;
  border-radius: 8px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 1px solid #b3b3b3;
}

.programImage {
  width: 100%;
  height: 250px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}



.program-name {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: #21409a;
  padding-top: 15px;
}

.program-age {
  /* line-height: 20px; */
  font-size: 21px;
  font-family: "ProximaNovaFont";
  color: #ed1846;
}

.program-description {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 15px 20px;
  color: #58595b;
  text-transform: capitalize;
  text-align: justify;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .programs-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
  }

  .program-box {
    width: 330px;
    height: auto;
    padding-bottom: 10px;
    border-radius: 8px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: 1px solid #b3b3b3;
  }
}