.privacy-policy-container {
    width: 100%;
    height: auto;
    padding: 30px;
    margin: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.privacy-policy-title {
    font-weight: 600;
    font-size: 32px;
    font-size: 2rem;
    padding-bottom: 10px;
}

.privacy-policy-subtitle {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 1.4em;
}

.privacy-policy-introduction,
.privacy-policy-contact,
.privacy-policy-content {
    padding-bottom: 15px;
    font-size: 18px;
    text-align: justify;
    padding-right: 30px;
}

.privacy-policy-link {
    color: #007BFF;
    text-decoration: none;
}

.privacy-policy-link:hover {
    text-decoration: underline;
}

.privacy-policy-section-title {
    font-size: 2rem;
    font-weight: 450;
    line-height: 1.25em;
    padding-bottom: 10px;
}

@media (max-width: 600px) {
    .privacy-policy-container {
        width: 100%;
        height: auto;
        padding: 10px;
    }
  }
