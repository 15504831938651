/* About.css */
.about-section {
  width: 100%;
  height: auto;
}

.about-content {
  background-image: url('https://heraldkids.com/images/about-banner.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 20px;
  min-height: 500px;
}

.about-header {
  background-image: url('https://heraldkids.com/images/banner-box.png');
  background-size: cover;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 457px;
  justify-content: center;
  left: 7%;
  padding: 79px 120px 98px 40px;
  position: absolute;
  text-align: center;
  width: 513px;
}

.about-header h1 {
  position: relative;
  z-index: 1;
  color: black;
  font-size: 40px;
  font-weight: bold;
}

.about-header p {
  position: relative;
  z-index: 1;
  color: black;
  font-size: 25px;
}

.about-body {
  background-image: url('https://heraldkids.com/images/pedagogy-bg.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding: 20px;
  color: white;
  width: 100%;
  height: 600px;
}

.about-description {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.about-description-title {
  font-size: 48px;
  font-weight: bold;
  color: #fff;
}


.school-title {
  font-size: 48px;
  font-weight: bold;
}

.about-description-text {
  width: 600px;
  line-height: 40px;
  text-align: justify;
  font-size: 20px;
}

.about-image {
  width: 100% !important;
  height: auto;
  padding-left: 30px;
}

.about-features {
  background-image: url('https://heraldkids.com/images/parent-bg.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 90px;
  padding: 20px;
  width: 100%;
  height: 350px;
}

.about-feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.about-feature-item p {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.school-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 90px;
  align-items: center;
  padding: 30px;
  color: white;
  width: 100%;
  height: 600px;
}

.school-descption {
  width: 600px;
  line-height: 25px;
  text-align: justify;
  font-size: 15px;
  color: #624dd6;
}

.school-image {
  width: 100%;
  height: auto;
  padding-bottom: 10px;
}

.school-title {
  font-size: 48px;
  font-weight: bold;
  color: #624dd6;
  padding-bottom: 20px;
}

@media (max-width: 600px) {
  .about-section {
    width: 100%;
    height: auto;
  }

  .about-content {
    width: 100%;
    height: auto;
  }
  .about-header {
    width: 100%;
    left: 0%;
    height: auto;
  }
  .about-body {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
  }

  .about-description-text {
    width: 100%;
    line-height: 20px;
    text-align: justify;
    font-size: 22px;
  }
  
  .about-image {
    width:100% ;
    height: auto;
    padding: none;
  }
  .image{
    width:100% ;
    height: auto;
  }
  .about-features {
    width: 100%;
    height: auto;
  }
  .about-feature-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .school-section {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .school-descption {
    width: 100%;
    line-height: 20px;
    text-align: justify;
    font-size: 22px;
    color: #624dd6;
  }
  
  .school-image {
    width: 100%;
    height: auto;
    align-items: center;
  }
  .misson-image{
    width: 100%;
    height: auto;
  }
}