.disclaimer-container {
    width: 100%;
    height: auto;
    padding: 30px;
    margin: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.disclaimer-header {
    font-weight: 600;
    font-size: 32px;
    font-size: 2rem;
    padding-bottom: 20px;
}

.disclaimer-title {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 1.4em;
    /* padding-bottom: 15px; */
}

.disclaimer-contact {
    font-size: 18px;
}

.disclaimer-section-title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.25em;
    padding-bottom: 10px;
}

.disclaimer-content {
    padding-bottom: 15px;
    font-size: 18px;
    text-align: justify;
    padding-right: 30px;
}

.disclaimer-link {
    color: #007BFF;
    text-decoration: none;
}

.disclaimer-link:hover {
    text-decoration: underline;
}

.disclaimer-consent-title,
.disclaimer-update-title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.25em;
    padding-bottom: 10px;
}

.disclaimer-consent-content,
.disclaimer-update-content {
    padding-bottom: 15px;
    font-size: 18px;
    text-align: justify;
    padding-right: 30px;
}

@media (max-width: 600px) {
    .disclaimer-container {
        width: 100%;
        height: auto;
        padding: 10px;
    }
}