/* About.css */
.gallery-section {
    width: 100%;
    height: auto;
}

.gallery-content {
    background-image: url("https://heraldkids.com/images/gallery-banner.jpg");
    background-size: cover;
    background-position: center;
    color: white;
    padding: 20px;
    min-height: 500px;
}

.gallery-header {
    background-image: url('https://heraldkids.com/images/banner-box.png');
    background-size: cover;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 457px;
    justify-content: center;
    left: 7%;
    padding: 79px 120px 98px 40px;
    position: absolute;
    text-align: center;
    width: 513px;
}

.gallery-header h1 {
    position: relative;
    z-index: 1;
    color: black;
    font-size: 40px;
    font-weight: bold;
}

.gallery-header p {
    position: relative;
    z-index: 1;
    color: black;
    font-size: 25px;
}

.festival-celebration-container {
    width: 100%;
    height: 400px;
}

.festival-title {
    text-align: center;
    font-size: 48px;
    font-weight: bold;
    padding-top: 20px;
    color: #624dd6;
}

.festival-details {
    text-align: left;
    padding-left: 40px;
}

.celebration-events {
    color: #624dd6;
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 10px;
}

.festival-description {
    font-size: 20px;
    font-weight: normal;
    color: #797979;
    margin: 0px;
    text-align: justify;
    line-height: 40px;
    padding-right: 40px;
}

.saraswati-puja-celebration {
    font-size: 20px;
    font-weight: normal;
    color: #797979;
    margin: 0px;
    text-align: justify;
    line-height: 40px;
    padding-right: 40px;
    padding-top: 10px;

}

.image-gallery {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-items: center;
    padding: 40px;
}

.gallery-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s;
}

.gallery-item img:hover {
    transform: scale(1.05);
}

@media (max-width: 600px) {
    .gallery-section {
        width: 100%;
        height: auto;
    }

    .gallery-content {
        width: 100%;
        height: auto;
    }

    .gallery-header {
        width: 100%;
        left: 0%;
        height: auto;
    }

    .festival-celebration-container {
        width: 100%;
        height: auto;
        padding: 10px;
    }

    .festival-title {
        text-align: center;
        font-size: 30px;
        font-weight: bold;
        padding-top: 20px;
        color: #624dd6;
    }

    .festival-details {
        text-align: center;
        padding: 10px;
    }

    .celebration-events {
        color: #624dd6;
        font-size: 25px;
        font-weight: bold;
        padding-bottom: 10px;
    }

    .festival-description {
        font-size: 18px;
        font-weight: normal;
        color: #797979;
        margin: 0px;
        text-align: justify;
        line-height: 35px;
        padding: 0px;
    }

    .saraswati-puja-celebration {
        font-size: 18px;
        font-weight: normal;
        color: #797979;
        margin: 0px;
        text-align: justify;
        line-height: 35px;
        padding-right: 0px;
        padding-top: 10px;

    }

    .image-gallery {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        justify-items: center;
        padding: 40px;
    }

    .gallery-item img {
        width: 100%;
        height: auto;
        border-radius: 8px;
        transition: transform 0.3s;
    }

    .gallery-item img:hover {
        transform: scale(1.05);
    }
}