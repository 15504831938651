.homepage-part4 {
  text-align: center;
  padding: 80px;
  height: 400px;
}

.facilities-title {
  font-family: Livvic, sans-serif;
  font-size: 30px;
  color: #212121;
  font-weight: bold;
  margin-bottom: 40px;
}

.facilities-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-wrap: wrap;
  gap: 20px;
}

.facility-item:hover {
  transform: scale(1.05);
}

.facility-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  width: 190px;
  border-radius: 10px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  transition: transform 0.2s;
}

.facility-image {
  max-height: 150px;
  width: auto;
  margin-bottom: 10px;
  background-color: none !important;
  background: none !important;
  background-image: none !important;
}

.facility-label {
  padding: 10px 0px;
  font-size: 14px;
  color: #212121;
  line-height: 21px;
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .homepage-part4 {
    text-align: center;
    padding: 10px;
    height: auto;
    width: 100%;
  }

  .facilities-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  .facility-item:hover {
    transform: scale(1.05);
  }

  .facility-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    /* background-color: #fff; */
    width: 230px;
    height: 190px;
    border-radius: 10px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
    transition: transform 0.2s;
  }
}